import React, { useState, useRef, useEffect } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus } from "@fortawesome/pro-light-svg-icons/faMinus"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"

import "./Accordion.scss"
import { Text } from "../Core"

const Accordion = ({
  number,
  heading,
  children,
  className,
  rightAlignHeading,
  startOpen = false
}) => {
  const collapsedRef = useRef()

  const [accordionHeight, setAccordionHeight] = useState(0)
  const [isOpen, setIsOpen] = useState(startOpen)

  useEffect(() => {
    if (startOpen && collapsedRef.current) {
      setAccordionHeight(collapsedRef.current.scrollHeight + "px")
      setIsOpen(true)
    }
  }, [collapsedRef.current, startOpen])

  const toggleAccordion = () => {
    if (isOpen) {
      setAccordionHeight(0)
    } else {
      setAccordionHeight(collapsedRef.current.scrollHeight + "px")
    }
    setIsOpen(!isOpen)
  }

  const contentStyles = {
    maxHeight: accordionHeight
  }

  return (
    <div
      className={`accordion ${className ? className : ""} ${
        isOpen ? "accordion__open" : ""
      }`}>
      <button
        aria-label="toggle accordion"
        className={`reset accordion__button ${
          rightAlignHeading ? "right" : ""
        } ${number ? "grid-3" : "grid-2"}`}
        onClick={toggleAccordion}>
        {number && <div className="accordion__number">{number}</div>}{" "}
        <Text as="h3" className="px-0 accordion__heading" text={heading} />
        <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} />
      </button>

      <div
        className={`accordion__content ${!number ? "clear-padding" : ""}`}
        style={contentStyles}
        ref={collapsedRef}>
        {children}
      </div>
    </div>
  )
}

export default Accordion
